<template>
  <div>
    <el-form :model="formData">
      <el-form-item v-show="isNaN(formData.id)">
        小提示：仅填写需要修改的项目信息，不需要修改的项目留白即可。
      </el-form-item>
      <el-form-item :style="ColorIt('生产日期')" label="生产日期" label-width="150px">
        <el-date-picker
          v-model="formData.生产日期"
          type="date"
          placeholder="-生产日期-"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item :style="ColorIt('机台')" label="机台" label-width="150px">
        <el-select
          v-model="formData.机台"
          filterable
          allow-create
          default-first-option
          clearable
          placeholder="-机台-"
        >
          <el-option v-for="item in (optionList.机台 || [])" :label="item" :value="item"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :style="ColorIt('结构')" label="结构" label-width="150px">
        <el-select
          v-model="formData.结构"
          filterable
          allow-create
          default-first-option
          clearable
          placeholder="-结构-"
        >
          <el-option v-for="item in (optionList.结构 || [])" :label="item" :value="item"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="RUN ID" label-width="150px">
        <el-input
          clearable
          v-model="formData.RUN_ID"
          placeholder="-RUN ID-"
        ></el-input>
      </el-form-item>
      <el-form-item label="圈" label-width="150px">
        <el-input
          clearable
          v-model="formData.圈"
          placeholder="-圈-"
        ></el-input>
      </el-form-item>
      <el-form-item :style="ColorIt('衬底尺寸')" label="衬底尺寸" label-width="150px">
        <el-select
          v-model="formData.衬底尺寸"
          filterable
          allow-create
          default-first-option
          clearable
          placeholder="-衬底尺寸-"
        >
          <el-option v-for="item in (optionList.衬底尺寸 || [])" :label="item" :value="item"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="原目检备注" label-width="150px" v-if="formData.id">
        <span v-html="formData.目检备注"></span>
      </el-form-item>
      <el-form-item label="表面等级" label-width="150px">
        <el-select
          v-model="formData.表面等级"
          filterable
          allow-create
          default-first-option
          clearable
          placeholder="-表面等级-"
        >
          <el-option v-for="item in (optionList.表面等级 || [])" :label="item" :value="item"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="追加目检备注" label-width="150px">
        <el-input
          clearable
          v-model="formData.追加目检备注"
          placeholder="追加目检备注"
          type="textarea"
          :rows="4"
        ></el-input>
      </el-form-item>
      <el-form-item label="衬底规格" label-width="150px">
        <el-input
          clearable
          v-model="formData.衬底规格"
          placeholder="-衬底规格-"
        ></el-input>
      </el-form-item>
      <el-form-item :style="ColorIt('报废判定')" label="报废判定" label-width="150px">
        <el-select
          v-model="formData.报废判定"
          filterable
          allow-create
          default-first-option
          clearable
          placeholder="-报废判定-"
        >
          <el-option v-for="item in (optionList.报废判定 || [])" :label="item" :value="item"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :style="ColorIt('生产类型')" label="生产类型" label-width="150px">
        <el-select
          v-model="formData.生产类型"
          filterable
          allow-create
          default-first-option
          clearable
          placeholder="-生产类型-"
        >
          <el-option v-for="item in (optionList.生产类型 || [])" :label="item" :value="item"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :style="ColorIt('入库日期')" label="入库日期" label-width="150px">
        <el-date-picker
          v-model="formData.入库日期"
          type="date"
          placeholder="-入库日期-"
        />
      </el-form-item>
      <el-form-item style="background:lightgoldenrodyellow" label="库状态" label-width="150px">
        <el-select
          v-model="formData.库状态"
          filterable
          allow-create
          default-first-option
          clearable
          placeholder="-库状态-"
        >
          <el-option v-for="item in ['I','O','W','T']" :value="item">{{ item }}</el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="库存位置" label-width="150px">
        <el-select
          v-model="formData.库存位置"
          filterable
          allow-create
          default-first-option
          clearable
          placeholder="-库存位置-"
        >
          <el-option label="PMC" value="PMC"></el-option>
          <el-option label="外延" value="外延"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="推荐版型" label-width="150px">
        <el-input
          clearable
          v-model="formData.推荐版型"
          placeholder="-推荐版型-"
        ></el-input>
      </el-form-item>
      <el-form-item label="波段" label-width="150px">
        <el-input
          clearable
          v-model="formData.波段"
          placeholder="-波段-"
        ></el-input>
      </el-form-item>
      <el-form-item label="综合等级" label-width="150px">
        <el-input
          clearable
          v-model="formData.综合等级"
          placeholder="-综合等级-"
        ></el-input>
      </el-form-item>
      <el-form-item label="版型" label-width="150px">
        <el-input
          clearable
          v-model="formData.版型"
          placeholder="-版型-"
        ></el-input>
      </el-form-item>
      <el-form-item label="类型" label-width="150px">
        <el-select
          v-model="formData.类型"
          filterable
          allow-create
          default-first-option
          clearable
          placeholder="-类型-"
        >
        </el-select>
      </el-form-item>
      <el-form-item label="挑片日期" label-width="150px">
        <el-date-picker
          v-model="formData.挑片日期"
          type="date"
          placeholder="-挑片日期-"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item :style="ColorIt('出库日期')" label="出库日期" label-width="150px">
        <el-date-picker
          v-model="formData.出库日期"
          type="date"
          placeholder="-出库日期-"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item :style="ColorIt('出库流向')" label="出库流向" label-width="150px">
        <el-select
          v-model="formData.出库流向"
          filterable
          allow-create
          default-first-option
          clearable
          placeholder="-出库流向-"
        >
          <el-option v-for="item in (optionList.出库流向 || [])" :label="item" :value="item"></el-option>
        </el-select>
      </el-form-item>
      <!--针对单行修改的-->
      <div v-if="formData.id">
        <el-form-item label="Wafer ID" label-width="150px">
          <el-input
            clearable
            v-model="formData.Wafer_ID"
            placeholder="-Wafer_ID-"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="激光编码" label-width="150px">
          <el-input
            clearable
            v-model="formData.激光编码"
            placeholder="-激光编码-"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="WLP" label-width="150px">
          <el-input
            clearable
            v-model="formData.WLP"
            placeholder="-WLP-"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="STD" label-width="150px">
          <el-input
            clearable
            v-model="formData.STD"
            placeholder="-STD-"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="Dom Lambda" label-width="150px">
          <el-input
            clearable
            v-model="formData.Dom_Lambda"
            placeholder="-Dom_Lambda-"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="lnt" label-width="150px">
          <el-input
            clearable
            v-model="formData.lnt"
            placeholder="-lnt-"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="FWHM" label-width="150px">
          <el-input
            clearable
            v-model="formData.FWHM"
            placeholder="-FWHM-"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="VF" label-width="150px">
          <el-input
            clearable
            v-model="formData.VF"
            placeholder="-VF-"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="IR" label-width="150px">
          <el-input
            clearable
            v-model="formData.IR"
            placeholder="-IR-"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="I" label-width="150px">
          <el-input
            clearable
            v-model="formData.I"
            placeholder="-I-"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="Ee" label-width="150px">
          <el-input
            clearable
            v-model="formData.Ee"
            placeholder="-Ee-"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="IP" label-width="150px">
          <el-input
            clearable
            v-model="formData.IP"
            placeholder="-IP-"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="WLP1" label-width="150px">
          <el-input
            clearable
            v-model="formData.WLP1"
            placeholder="-WLP1-"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="WLP STD" label-width="150px">
          <el-input
            clearable
            v-model="formData.WLP_STD"
            placeholder="-WLP_STD-"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="WLD" label-width="150px">
          <el-input
            clearable
            v-model="formData.WLD"
            placeholder="-WLD-"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="WD STD" label-width="150px">
          <el-input
            clearable
            v-model="formData.WD_STD"
            placeholder="-WD_STD-"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="HW" label-width="150px">
          <el-input
            clearable
            v-model="formData.HW"
            placeholder="-HW-"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="色纯度" label-width="150px">
          <el-input
            clearable
            v-model="formData.色纯度"
            placeholder="-色纯度-"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="翘曲" label-width="150px">
          <el-input
            clearable
            v-model="formData.翘曲"
            placeholder="-翘曲-"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="厚度" label-width="150px">
          <el-input
            clearable
            v-model="formData.厚度"
            placeholder="-厚度-"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="厚度std" label-width="150px">
          <el-input
            clearable
            v-model="formData.厚度std"
            placeholder="-厚度std-"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="Bulk Concentration" label-width="150px">
          <el-input
            clearable
            v-model="formData.Bulk_Concentration"
            placeholder="-Bulk_Concentration-"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="Sheet Concentration" label-width="150px">
          <el-input
            clearable
            v-model="formData.Sheet_Concentration"
            placeholder="-Sheet_Concentration-"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="Mobility" label-width="150px">
          <el-input
            clearable
            v-model="formData.Mobility"
            placeholder="-Mobility-"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="Resistivity" label-width="150px">
          <el-input
            clearable
            v-model="formData.Resistivity"
            placeholder="-Resistivity-"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="XRD102" label-width="150px">
          <el-input
            clearable
            v-model="formData.XRD102"
            placeholder="-XRD102-"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="XRD002" label-width="150px">
          <el-input
            clearable
            v-model="formData.XRD002"
            placeholder="-XRD002-"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="备用列1" label-width="150px">
          <el-input
            clearable
            v-model="formData.备用列1"
            placeholder="-备用列1-"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="备用列2" label-width="150px">
          <el-input
            clearable
            v-model="formData.备用列2"
            placeholder="-备用列2-"
            style="width:300px"
          />
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "ExtForm",
  props: ['formName', 'extFormData', 'optionList'],
  data() {
    return {
      formData: JSON.parse(JSON.stringify(this.extFormData)),
    }
  },
  methods: {
    EmitFormData() {
      this.formData.formName = this.formName
      this.$emit('emit-form-data', this.formData)
    }
  },
  watch: {
    extFormData: function (val, oldVal) {
      this.formData = JSON.parse(JSON.stringify(val))
    }
  },
  computed: {
    ColorIt() {
      return input => {
        if (this.formData.库状态 === 'I') {
          const 必填项目 = ['生产日期', '机台', '入库日期', '结构', '生产类型', '衬底尺寸', '报废判定']
          const chk = 必填项目.find(item => item === input)
          if (chk) return 'background:rgba(0,255,0,0.1)'
        }
        if (this.formData.库状态 === 'O') {
          const 必填项目 = ['出库日期', '结构', '生产类型', '衬底尺寸', '出库流向']
          const chk = 必填项目.find(item => item === input)
          if (chk) return 'background:rgba(255,0,0,0.1)'
        }
        return ''
      }
    }
  },
}
</script>

<style scoped>
</style>
